@use '@angular/material' as mat;
@include mat.core();

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@media print {
  .noprint {
    display: none !important;
  }

  .toolbar {
    display: none;
  }
}

/********** Levin's Material Style changes ***********/

mat-action-list {
  border-left: solid 1px var(--mdc-outlined-button-outline-color, var(--mat-sys-outline));
  border-top: solid 1px var(--mdc-outlined-button-outline-color, var(--mat-sys-outline));
  border-bottom: solid 1px var(--mdc-outlined-button-outline-color, var(--mat-sys-outline));
  border-radius: 1rem 0 0 1rem;
  overflow: auto;
}

.mat-mdc-dialog-surface,
.mdc-dialog__surface {
  padding: 1rem;
}

.mat-accordion .mat-expansion-panel {
  overflow: visible;
}

.mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 1rem 0 1rem;
}

.mat-expansion-panel-content-wrapper .mat-expansion-panel-body {
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.mat-drawer .mat-drawer-inner-container {
  overflow: unset;
}

.mat-drawer > .mat-drawer-inner-container > .ng-star-inserted > .ng-star-inserted > .titleCard,
.mat-drawer > .mat-drawer-inner-container > .ng-star-inserted > .ng-star-inserted > .stack,
.mat-drawer > .mat-drawer-inner-container > .ng-star-inserted > .flex-label {
  padding: 1rem;
}

.mdc-button,
.mat-mdc-button,
.mat-mdc-button-base {
  border-radius: 2rem !important;
}

.cdk-overlay-pane {
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 0.25rem;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-overlay-pane:not(:has(mat-option)) {
  box-shadow: none;
}

.cdk-overlay-pane:has(button) {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: none;
}

mat-accordion mat-expansion-panel {
  .mat-expansion-panel-content {
    height: 0;
  }
  &.mat-expanded {
    .mat-expansion-panel-content {
      height: auto;
    }
  }
}

fieldset.like-mat-form-field-outline {
  margin: 0;
  border-style: solid;
  border-width: var(--mdc-outlined-text-field-outline-width);
  border-color: var(--mdc-outlined-text-field-outline-color);
  border-radius: var(--mdc-outlined-text-field-container-shape);
}
fieldset.like-mat-form-field-outline > legend {
  font-size: calc(var(--mat-form-field-outlined-label-text-populated-size) * var(--mat-mdc-form-field-floating-label-scale, 0.75));
  color: var(--mdc-outlined-text-field-label-text-color);
  font-family: var(--mdc-outlined-text-field-label-text-font);
  font-weight: var(--mdc-outlined-text-field-label-text-weight);
  letter-spacing: var(--mdc-outlined-text-field-label-text-tracking);
  white-space: nowrap;
}

.ol-scale-bar.scale-print {
  padding: 20px 20px 2px 10px;
}
.ol-scale-bar.scale-print .ol-scale-bar-inner {
  position: relative;
}
#map .ol-scale-bar {
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
  left: 1.25rem;
  bottom: auto;
}

#map .ol-attribution {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  border-radius: 4px 4px 0 0;
}
#map .ol-attribution li:not(:first-child) {
  border-left: 1px solid var(--ol-foreground-color);
  padding-left: 2px;
}

/*for any reason the one in map-legend-display.component.css are not applied*/
.legend-img {
  display: block;
}
